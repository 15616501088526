import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import Markdown from 'react-markdown';
import MarkdownRenderers from '../../../../utils/MarkdownRenderers';
import { useWarnOutboundLink } from '../../../../hooks/useWarnOutboundLink';

const TextInputPreview: FC<ActionPreviewBaseProps<string>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const { placeholders } = useFormRendererInfo();
  const externalLinkWarner = useWarnOutboundLink();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  if (answerOnly) {
    return <>{response || '-'}</>;
  }

  return (
    <>
      <ActionPreview
        data-cy="text-input-preview"
        question={title}
        description={previewDescription}
        answer={<Markdown components={MarkdownRenderers(placeholders, { a: { onClick: externalLinkWarner.onClick } })}>{response || '\\-'}</Markdown>}
      />
      {externalLinkWarner.modal}
    </>
  );
};

export default TextInputPreview;
